import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`npx albertoleal`}</h1>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/albertoleal"
      }}>{`github.com/dashed/albertoleal`}</a></p>
    <p>{`My personal npm card.`}</p>
    <pre><code parentName="pre" {...{}}>{`npx albertoleal
`}</code></pre>
    <p>{`Requires Node.js and npm with `}<a parentName="p" {...{
        "href": "https://blog.npmjs.org/post/162869356040/introducing-npx-an-npm-package-runner"
      }}>{`npx`}</a>{`.`}</p>
    <p>{`Blatantly stolen from `}<a parentName="p" {...{
        "href": "https://github.com/sindresorhus/sindresorhus"
      }}>{`https://github.com/sindresorhus/sindresorhus`}</a></p>
    <br />
    <br />
    <hr />
    <br />
    <ImageSource src="npx-albertoleal/screenshot.png" alt="npx albertoleeal" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      