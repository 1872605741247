// 3rd-party imports

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// local imports

import Layout from "./layout";
import SEO from "./seo";

// default template for MDX files
// https://gatsby-mdx.netlify.com/guides/using-mdx-layouts

const PostTemplate = props => {
  const { children, pageContext } = props;

  const { title, description, keywords } = pageContext.frontmatter || {};
  return (
    <Layout>
      <p>
        <Link to="/">Go home</Link>
      </p>
      <br />
      <SEO {...{ title, description, keywords }} />
      {children}
    </Layout>
  );
};

PostTemplate.propTypes = {
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string.isRequired,
      keywords: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

export default PostTemplate;
